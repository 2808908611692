import { useEffect, useState } from "react";
import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	useLocation,
	useHistory
} from "react-router-dom";

function LeftNav({ functions, user, notifications, homeAction }) {
	const [displayed, setDisplayed] = useState(false);

	const history = useHistory();

	let { pathname } = useLocation();

	const logout = (e) => {
		e.preventDefault();
		functions.logout();
		navigate(null, "/");
	};

	const toggle = (e) => {
		setDisplayed((disp) => {
			return !disp;
		});
	};

	const navigate = (e, url) => {
		setDisplayed(false);
		if (url == null) {
			history.push(e.currentTarget.getAttribute("data-url"));
		} else {
			history.push(url);
		}
	};

	const home = (e) => {
		if (homeAction === "scroll") {
			setDisplayed(false);
			document.getElementsByClassName("main")[0].scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		} else {
			navigate(null, "/");
		}
	};

	let notifications_alert = null;
	for (let i = 0; notifications != null && i < notifications.length; i++) {
		if (!notifications[i].seen) {
			notifications_alert = <div className="nav-alert" />;
			break;
		}
	}

	return [
		<div
			key="mob"
			className={
				"left-nav-control gone " + (displayed ? "displayed" : "")
			}
			onClick={toggle}>
			<div>
				<div key="1" />
				<div key="2" />
				<div key="3" />
			</div>

			{notifications_alert}
		</div>,
		<div
			key="main"
			className={"left-nav " + (displayed ? "displayed" : "")}>
			<div onClick={home} data-url="/" key="home">
				<font className="icon-consumie" />
				<span>Home</span>
			</div>
			<div
				key="bm"
				onClick={user.id == null ? null : navigate}
				data-url="/bookmarks"
				className={user.id == null ? "inactive" : ""}>
				<font className="icon-bookmark" />
				<span>Bookmarks</span>
			</div>
			<div key="srch" onClick={navigate} data-url="/discover">
				<font className="icon-search" />
				<span>Discover</span>
			</div>
			<div
				key="not"
				onClick={user.id == null ? null : navigate}
				data-url="/notifications"
				className={user.id == null ? "inactive" : ""}>
				<font className="icon-notifications" />
				{notifications_alert}
				<span>Notifications</span>
			</div>
			<div key="lists" onClick={navigate} data-url={"/lists/"}>
				<font className="icon-list" />
				<span>Lists</span>
			</div>
			<div
				key="user"
				onClick={user.id == null ? null : navigate}
				data-url={"/user/" + user.name}
				className={user.id == null ? "inactive" : ""}>
				<font className="icon-profile" />
				<span>Profile</span>
			</div>
			<div
				key="sign"
				onClick={user.id == null ? navigate : logout}
				data-url="/login">
				<font
					className={
						user.id == null ? "icon-login" : "icon-exit"
					}
				/>
				<span>{user.id == null ? "Sign In" : "Sign Out"}</span>
			</div>
			<Link to="/add" className={user.id == null ? "gone" : ""}>
				<font className="icon-plus-plain" />
			</Link>
		</div>
	];
}
export default LeftNav;
