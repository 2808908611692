import "./App.scss";
import "./Icons.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import SmallTypes from "./Util/SmallTypes";
import ContentRouter from "./ContentRouter";

const server =
	process.env.NODE_ENV === "development"
		? "https://dev-api.consumie.com"
		: "https://api.consumie.com";

const scrolls = {};

function App() {
	const cookie = Cookies.get("u");

	const defaultFeedState = {
		last_date: {},
		data: {},
		loading: false,
		current: {
			order: "new",
			activity: 0,
			type: null,
			search: null,
			audience: "network",
			userID: null
		},
		cached_currents: {
			0: {
				order: "new",
				activity: 0,
				type: null,
				search: null,
				audience: "network",
				userID: null
			},
			4: {
				order: "new",
				activity: 4,
				type: null,
				search: null,
				audience: "me",
				userID: null
			}
		},
		matching: {}
	};

	const [mainDataStore, setMainDataStore] = useState({
		feeds: {},
		consumption: {},
		content: {},
		users: {}
	});

	const defaultCurrentFeed = {
		default: {
			activity: 0,
			sort: "date-desc",
			type: null,
			search: null,
			audience: "network",
			userName: null
		},
		bookmarks: {
			activity: 4,
			sort: "date-desc",
			type: null,
			search: null,
			audience: "me",
			userName: null
		},
		user: {
			activity: 0,
			sort: "date-desc",
			type: null,
			search: null,
			audience: "all",
			userName: null
		},
		state: ""
	};

	const [currentFeed, setCurrentFeed] = useState(defaultCurrentFeed);

	const [display, setDisplay] = useState({});
	const [user, setUser] = useState(
		typeof cookie === "undefined" ? { id: null } : JSON.parse(cookie)
	);
	const [feed, setFeed] = useState(defaultFeedState);
	const [contentCache, setContentCache] = useState({});
	const [userCache, setUserCache] = useState({});
	const [notifications, setNotifications] = useState([]);
	const [lastPoll, setLastPoll] = useState();
	const [searchSource, setSearchSource] = useState();
	const [recommendations, setRecommendations] = useState();
	const [lists, setLists] = useState({
		lists: [],
		page: 1,
		cached: {}
	});

	const no_connection_error = {
		error: {
			message: "Could not connect to server"
		}
	};

	useEffect(() => {
		if (currentFeed[currentFeed.state] != null) {
			const feedID = JSON.stringify(currentFeed[currentFeed.state]);
			if (mainDataStore.feeds[feedID] == null) {
				getV2Consumption({});
			}
		}
	}, [currentFeed]);

	const updateMainDataStore = ({
		feeds = [],
		consumptions = [],
		contents = [],
		users = [],
		comments = []
	}) => {
		// console.log({
		// 	feeds: JSON.parse(JSON.stringify(feeds)),
		// 	consumptions: JSON.parse(JSON.stringify(consumptions)),
		// 	contents: JSON.parse(JSON.stringify(contents)),
		// 	users: JSON.parse(JSON.stringify(users)),
		// 	comments: JSON.parse(JSON.stringify(comments))
		// });
		setMainDataStore((oldStore) => {
			const newStore = { ...oldStore };
			feeds.forEach((feed) => {
				if (newStore.feeds[feed.id] == null) {
					newStore.feeds[feed.id] = {
						id: feed.id,
						offset: null,
						data: {},
						loading: false,
						complete: false
					};
				}
				if (feed.data != null) {
					feed.data.forEach((d) => {
						if (d.delete === true) {
							delete newStore.feeds[feed.id].data[d];
						} else {
							newStore.feeds[feed.id].data[d.id] =
								d.position;
						}
						consumptions.push({ ...d });
					});
				}
				Object.keys(feed).forEach((key) => {
					if (key != "data") {
						newStore.feeds[feed.id][key] = feed[key];
					}
				});
			});
			// loop through consumptions and content to see if we need to supplement
			// existing ararys

			consumptions.forEach((c) => {
				if (c.content != null) {
					contents.push({ ...c.content });
				}
				if (c.user != null) {
					users.push({ ...c.user });
				}
				if (c.replies != null) {
					c.replies.forEach((r) => {
						users.push({ ...r.user });
					});
				}
			});
			contents.forEach((c) => {
				if (c.reviews != null) {
					c.reviews.forEach((r) => {
						consumptions.push({ ...r });
					});
				}
				if (c.activities != null) {
					c.activtities.forEach((a) => {
						consumptions.push({ ...a });
					});
				}
				if (c.similar != null) {
					c.similar.forEach((s) => {
						contents.push({ ...s });
					});
				}
				if (c.parent != null) {
					contents.push({ ...c.parent });
				}
				if (c.grandparent != null) {
					contents.push({ ...c.grandparent });
				}
				if (c.children != null) {
					c.children.forEach((c) => {
						contents.push({ ...c });
					});
				}
			});

			consumptions.forEach((c) => {
				if (c.delete === true || c.deleted === true) {
					delete newStore.consumption[c.id];
				} else {
					if (feeds.length == 0) {
						// If this is coming in from something other than a feed
						// see if we need to add it to a feed
						addConsumptionToFeeds({
							feeds: newStore.feeds,
							consumption: c,
							content: newStore.content
						});
					}
					if (c.content != null) {
						c.content = { id: c.content.id };
					}
					if (c.user != null) {
						c.user = { id: c.user.id };
					}
					if (c.replies != null) {
						c.replies.forEach((r) => {
							r.user = { id: r.user.id };
						});
					}
					if (newStore.consumption[c.id] == null) {
						newStore.consumption[c.id] = c;
					} else {
						Object.keys(c).forEach((key) => {
							newStore.consumption[c.id][key] = c[key];
						});
					}
				}
			});
			contents.forEach((c) => {
				if (c.reviews != null) {
					c.reviews.forEach((r) => {
						r = { id: r.id };
					});
				}
				if (c.activities != null) {
					c.activities.forEach((a) => {
						a = { id: a.id };
					});
				}
				if (c.similar != null) {
					c.similar.forEach((s) => {
						s = { id: s.id };
					});
				}
				if (c.parent != null) {
					c.parent = { id: c.parent.id };
				}
				if (c.grandparent != null) {
					c.grandparent = { id: c.grandparent.id };
				}
				if (c.children != null) {
					c.children.forEach((ch, idx) => {
						// I honestly have no idea why I had to do this this way
						// when I could just set them directly for everything else
						c.children[idx] = { id: ch.id };
					});
				}
				if (newStore.content[c.id] == null) {
					newStore.content[c.id] = c;
				} else {
					Object.keys(c).forEach((key) => {
						newStore.content[c.id][key] = c[key];
					});
				}
			});
			users.forEach((u) => {
				if (u.clear === true) {
					// Remove a particular user from feeds -- used when you unfollow someone
					Object.keys(newStore.feeds).forEach((key) => {
						const params = JSON.parse(key);
						if (params.audience != "all") {
							Object.keys(
								newStore.feeds[key].data
							).forEach((consumptionID) => {
								if (
									newStore.consumption[consumptionID]
										.user.id == u.id
								) {
									delete newStore.feeds[key].data[
										consumptionID
									];
								}
							});
						}
					});
				}
				delete u.clear;
				if (newStore.users[u.id] == null) {
					newStore.users[u.id] = u;
				} else {
					Object.keys(u).forEach((key) => {
						newStore.users[u.id][key] = u[key];
					});
				}
			});
			comments.forEach((c) => {
				const consumptionID = c.consumptionID;
				delete c.consumptionID;
				if (consumptionID != null) {
					newStore.consumption[consumptionID].replies.forEach(
						(r) => {
							if (r.id == c.id) {
								Object.keys(c).forEach((key) => {
									r[key] = c[key];
								});
							}
						}
					);
				}
			});
			// console.log(JSON.parse(JSON.stringify(newStore)));
			return newStore;
		});
	};
	const addConsumptionToFeeds = ({ feeds, consumption, content }) => {
		// console.log({
		// 	location: "addConsumptionToFeeds",
		// 	feeds: JSON.parse(JSON.stringify(feeds)),
		// 	consumption: JSON.parse(JSON.stringify(consumption))
		// });
		Object.keys(feeds).forEach((key) => {
			if (consumption.content != null) {
				const settings = JSON.parse(key);

				const c =
					consumption.content.type == null
						? content[consumption.content.id]
						: consumption.content;

				let okay = false;
				if (consumption.action.id == settings.activity) {
					okay = true;
				}
				if (okay && settings.type != null) {
					if (settings.type == c.type) {
						okay = true;
					} else if (
						settings.type == "TV" &&
						(c.type == "TV-SHOW" ||
							c.type == "TV-EPISODE" ||
							c.type == "TV-SEASON")
					) {
						okay = true;
					} else if (
						settings.type == "PODCAST" &&
						(c.type == "PODCAST" ||
							c.type == "PODCAST-EPISODE")
					) {
						okay = true;
					} else {
						okay = false;
					}
				}
				if (okay && settings.audience != null) {
					if (
						settings.audience == "me" &&
						consumption.distance > 0
					) {
						okay = false;
					} else if (
						settings.audience == "network" &&
						consumption.distance > 1
					) {
						okay = false;
					}
				}
				if (
					okay &&
					settings.search != null &&
					settings.search != ""
				) {
					let search_terms = settings.search.split(" ");
					let matched = true;
					let compare = c.link;
					search_terms.forEach((term) => {
						term = term.toLowerCase().replace(/\W/g, "");
						if (!compare || compare.indexOf(term) == -1) {
							matched = false;
						}
					});
					okay = matched;
				}

				if (okay) {
					let position = "";
					switch (settings.sort) {
						case "date-desc":
						case "date-asc":
							position = new Date(
								consumption.logged
							).getTime();
							if (
								(settings.sort == "date-desc" &&
									position < feeds[key].offset) ||
								(settings.sort == "date-asc" &&
									position > feeds[key].offset)
							) {
								position = "";
							}
							break;
						case "rating-desc":
						case "rating-asc":
							if (consumption.rating != null) {
								const item_rating = consumption.rating;
								const item_time = new Date(
									consumption.logged
								).getTime();
								position = `${item_rating} ${item_time}`;
								let offset =
									feeds[key].offset.split(" ");
								let rating_offset = parseInt(offset[0]);
								let time_offset = parseInt(offset[1]);
								// This could probably look at time as well, but that was
								// making my head hurt, so just doing rating for now
								if (
									(settings.sort == "rating-desc" &&
										item_rating <
											rating_offset) ||
									(settings.sort == "rating-asc" &&
										item_rating > rating_offset)
								) {
									position = "";
								}
							}
							break;
					}
					if (position != "") {
						feeds[key].data[consumption.id] = position;
					}
				}
			}
		});
	};

	const changeCurrentFeed = ({ feed, state }) => {
		setCurrentFeed((oldFeed) => {
			const newFeed = { ...oldFeed };
			if (state == null) {
				state = oldFeed.state;
			}
			newFeed.state = state;
			if (newFeed[state] == null) {
				newFeed[state] = {};
			}
			Object.keys(feed).forEach((key) => {
				newFeed[state][key] = feed[key];
			});
			return newFeed;
		});
	};
	const getCurrentFeed = () => {
		return currentFeed[currentFeed.state];
	};
	const extendCurrentFeed = () => {
		getV2Consumption({});
	};

	const getV2Consumption = async ({ consumptionID }) => {
		let params = {};
		let feedID = "";
		if (consumptionID == null) {
			feedID = JSON.stringify(getCurrentFeed());
			if (
				mainDataStore.feeds[feedID] != null &&
				mainDataStore.feeds[feedID].complete
			) {
				return;
			}
			updateMainDataStore({
				feeds: [
					{
						id: feedID,
						loading: true
					}
				]
			});
			const offset =
				mainDataStore.feeds[feedID] == null
					? null
					: mainDataStore.feeds[feedID].offset;
			params = {
				sort: getCurrentFeed().sort,
				activities: [getCurrentFeed().activity],
				type: getCurrentFeed().type,
				search: getCurrentFeed().search,
				audience: getCurrentFeed().audience,
				userName: getCurrentFeed().userName,
				last_date: offset
			};
		} else {
			params = {
				id: consumptionID
			};
		}
		const response = await axios.post(
			server + "/v2/consumption",
			params,
			{
				headers: {
					Authorization: user.token
				}
			}
		);
		updateMainDataStore({
			feeds: [
				{
					id: feedID,
					complete: response.data.end === true,
					offset: response.data.offset,
					loading: false,
					data: response.data.data
				}
			]
		});
	};

	const selectUserDetails = ({ id, username }) => {
		let out = {};
		if (id != null && mainDataStore.users[id] != null) {
			out = mainDataStore.users[id];
		} else if (username != null) {
			const filtered = Object.keys(mainDataStore.users).filter(
				(id) => {
					return mainDataStore.users[id].name == username;
				}
			);
			out =
				filtered.length == 0
					? {}
					: mainDataStore.users[filtered[0]];
		}
		if (out.id == null) {
			return out;
		}
		if (out.id == user.id) {
			Object.keys(user).forEach((key) => {
				out[key] = user[key];
			});
		}
		return out;
	};

	const selectCurrentFeedContents = ({ group }) => {
		const feedID =
			currentFeed == null ? null : JSON.stringify(getCurrentFeed());
		if (feedID == null || mainDataStore.feeds[feedID] == null) {
			return {
				status: "uninitialized",
				feed: [],
				parameters: getCurrentFeed(),
				key: feedID,
				user: null
			};
		}
		let user = null;
		if (getCurrentFeed().userName != null) {
			let userMatch = Object.keys(mainDataStore.users).filter((u) => {
				return (
					mainDataStore.users[u].name ==
					getCurrentFeed().userName
				);
			});
			user =
				userMatch.length == 0
					? null
					: mainDataStore.users[userMatch[0]];
		}
		let out = [];
		Object.keys(mainDataStore.feeds[feedID].data).forEach((consID) => {
			try {
				const c = { ...mainDataStore.consumption[consID] };
				c.position = mainDataStore.feeds[feedID].data[consID];
				c.content = mainDataStore.content[c.content.id];
				c.user = mainDataStore.users[c.user.id];
				out.push(c);
			} catch (e) {}
		});

		out.sort((a, b) => {
			let diff = 0;
			if (getCurrentFeed().sort === "date-desc") {
				diff = b.position - a.position;
			} else if (getCurrentFeed().sort === "date-asc") {
				diff = a.position - b.position;
			} else if (getCurrentFeed().sort === "rating-desc") {
				const a_split = a.position.split(" ");
				const b_split = b.position.split(" ");
				if (a_split[0] != b_split[0]) {
					diff = parseInt(b_split[0]) - parseInt(a_split[0]);
				} else {
					diff = parseInt(b_split[1]) - parseInt(a_split[1]);
				}
			}
			return diff;
		});
		if (group !== true) {
			return {
				status: mainDataStore.feeds[feedID].loading
					? "loading"
					: "loaded",
				feed: out,
				parameters: getCurrentFeed(),
				key: feedID,
				user: user
			};
		}

		const mobile = window.innerWidth < 650;
		let grouped = [];

		for (let i = 0; i < out.length; i++) {
			let item = out[i];
			let next_item = {};
			let shrink = false;
			if (i < out.length - 1) {
				next_item = out[i + 1];
				let this_type = item.content.type;
				let next_type = next_item.content.type;
				shrink =
					mobile ||
					(SmallTypes[this_type] == 1 &&
						SmallTypes[next_type] == 1);
			}
			if (shrink) {
				grouped.push([item, next_item]);
				i++;
			} else {
				grouped.push([item]);
			}
		}
		return {
			status: mainDataStore.feeds[feedID].loading
				? "loading"
				: "loaded",
			feed: grouped,
			parameters: getCurrentFeed(),
			key: feedID,
			user: user
		};
	};

	const getScrollPosition = ({ key }) => {
		if (scrolls[key] != null) {
			return scrolls[key];
		} else {
			return 0;
		}
	};

	const setScrolls = ({ key, position }) => {
		scrolls[key] = position;
	};

	const selectContentByPath = ({ path }) => {
		const trimmed = trimPath(path);
		const filtered = Object.keys(mainDataStore.content).filter((k) => {
			if (mainDataStore.content[k].url == trimmed.path) {
				return true;
			}
		});
		if (filtered.length == 0) {
			return {};
		} else {
			const content = { ...mainDataStore.content[filtered[0]] };

			// Probably need to sort all of the below
			if (content.activity != null) {
				content.activity = content.activity.map((a) => {
					const activity = {
						...mainDataStore.consumption[a.id]
					};
					activity.user = mainDataStore.users[activity.user.id];
					activity.content =
						mainDataStore.content[activity.content.id];
					return activity;
				});
			}
			if (content.reviews != null) {
				content.reviews = content.reviews.map((r) => {
					const review = { ...mainDataStore.consumption[r.id] };
					review.user = mainDataStore.users[review.user.id];
					review.content =
						mainDataStore.content[review.content.id];
					return review;
				});
			}
			if (content.similar != null) {
				content.similar = content.similar.map((s) => {
					return mainDataStore.content[s.id];
				});
			}
			if (content.parent != null) {
				content.parent = {
					...mainDataStore.content[content.parent.id]
				};
			}
			if (content.grandparent != null) {
				content.grandparent = {
					...mainDataStore.content[content.grandparent.id]
				};
			}
			if (content.children != null) {
				content.children = content.children.map((c) => {
					const child = { ...mainDataStore.content[c.id] };
					return child;
				});
			}
			return content;
		}
	};

	const getContent = async ({ path, id }) => {
		let content = null;
		if (path == null) {
			content = mainDataStore.content[id];
		}
		if (content == null && path != null) {
			content = selectContentByPath({ path: path });
		}
		const trimmed = trimPath(path);
		if (content == null || content.data == null) {
			try {
				const content_response = await axios.post(
					server + "/content",
					{
						name: path != null ? trimmed.path : null,
						id: id
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				content = content_response.data;
				updateMainDataStore({ contents: [content] });
			} catch (e) {
				console.log(e);
			}
		}
		if (content.reviews == null) {
			try {
				const review_response = await axios.post(
					server + "/v2/consumption",
					{
						content_id: content.id,
						audience: "all",
						has_review: true
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);

				updateMainDataStore({
					consumptions: review_response.data.data,
					contents: [
						{
							id: content.id,
							reviews: review_response.data.data.map(
								({ id }) => {
									return { id: id };
								}
							)
						}
					]
				});
			} catch (e) {
				console.log(e);
			}
		}
		if (content.activity == null) {
			try {
				const activity_response = await axios.post(
					server + "/v2/consumption",
					{
						content_id: content.id,
						audience: "network"
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				updateMainDataStore({
					consumptions: activity_response.data.data,
					contents: [
						{
							id: content.id,
							activity: activity_response.data.data.map(
								({ id }) => {
									return { id: id };
								}
							)
						}
					]
				});
			} catch (e) {
				console.log(e);
			}
		}
		if (content.similar == null) {
			try {
				const similar_response = await axios.post(
					server + "/similar",
					{
						id: content.id
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				updateMainDataStore({
					contents: similar_response.data.similar
						.map(
							({
								id,
								name,
								image,
								parenthetical,
								url,
								type
							}) => {
								return {
									id: id,
									name: name,
									image: image,
									parenthetical: parenthetical,
									url: url,
									type: type
								};
							}
						)
						.concat({
							id: content.id,
							similar: similar_response.data.similar.map(
								({ id }) => {
									return { id: id };
								}
							)
						})
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	const selectConsumptionDetails = ({ id }) => {
		if (mainDataStore.consumption[id] == null) {
			return {};
		} else {
			let out = { ...mainDataStore.consumption[id] };
			out.content = { ...mainDataStore.content[out.content.id] };
			if (out.replies != null) {
				out.replies = out.replies.map((r) => {
					const reply = { ...r };
					reply.user = mainDataStore.users[reply.user.id];
					return reply;
				});
			}
			out.user = { ...mainDataStore.users[out.user.id] };
			return out;
		}
	};

	const getConsumptionReplies = async ({ id }) => {
		if (
			mainDataStore.consumption[id] == null ||
			mainDataStore.consumption[id].replies != null
		) {
			return;
		}
		updateMainDataStore({
			consumptions: [
				{
					id: id,
					replies: []
				}
			]
		});
		try {
			const response = await axios.post(
				server + "/v2/consumption/replies",
				{
					id: id
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			updateMainDataStore({
				consumptions: [
					{
						id: id,
						replies: response.data
					}
				]
			});
		} catch (e) {}
	};

	// OOOOOOOOOOOLLLLLLLLLLDDDDDDD STUFF

	useEffect(() => {
		getNotifications();
	}, [user]);

	useEffect(() => {
		heartbeat();
	}, [lastPoll]);

	useEffect(() => {
		const delay = 15000;
		const timer = setInterval(() => {
			setLastPoll(new Date().getTime() - delay);
		}, delay);
		return () => {
			clearInterval(timer);
		};
	}, []);

	const getNotifications = async (since, before) => {
		const response = await axios.post(
			server + "/notifications",
			{
				since: since,
				before: before
			},
			{
				headers: {
					Authorization: user.token
				}
			}
		);
		if (response.data.error != null) {
			return false;
		}
		setNotifications((old_nots) => {
			return old_nots.concat(response.data);
		});
		return response.data != null && response.data.length > 0;
	};

	const markNotificationsSeen = async (id) => {
		let ids = [];
		for (
			let j = 0;
			notifications != null && j < notifications.length;
			j++
		) {
			if (
				notifications[j].seen === false &&
				(id == null || id == notifications[j].object.id)
			) {
				ids.push(notifications[j].id);
			}
		}
		if (ids.length > 0) {
			const response = await axios.post(
				server + "/notifications_seen",
				{
					ids: ids
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			setNotifications((ns) => {
				let new_ns = [...ns];
				for (let i = 0; i < new_ns.length; i++) {
					new_ns[i].seen = true;
				}
				return new_ns;
			});
		}
	};

	const heartbeat = async () => {
		if (user.id != null && lastPoll != null) {
			const active = Object.keys(mainDataStore.content).filter((c) => {
				return mainDataStore.content[c].data != null;
			});

			// Go through all the feeds to find the widest audience

			const params = {
				since: lastPoll,
				active: active
			};
			let audience = "me";
			Object.keys(mainDataStore.feeds).forEach((key) => {
				const settings = JSON.parse(key);
				if (settings.audience == "network" && audience == "me") {
					audience = "network";
				} else if (settings.audience == "all") {
					audience = "all";
				}
			});
			params.audience = audience;
			const response = await axios.post(
				server + "/heartbeat",
				params,
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			if (response.data.error != null) {
				return;
			}
			if (Object.keys(response.data).length == 0) {
				return;
			}
			if (response.data.user != null) {
				const updated_user = {
					...user,
					data: {
						...user.data,
						bookmarks: response.data.user.data.bookmarks
					}
				};
				setUser((user) => updated_user);
				Cookies.set("u", updated_user, { expires: 365 * 5 });
			}
			updateMainDataStore({
				consumptions:
					response.data.consumption == null
						? []
						: response.data.consumption.data,
				users:
					response.data.user == null ? [] : [response.data.user],
				contents:
					response.data.stats == null
						? []
						: response.data.stats.data
			});
		}
	};

	const sendRecommendation = async ({
		contentID,
		userID,
		email,
		message
	}) => {
		try {
			const response = await axios.post(
				server + "/recommend",
				{
					id: contentID,
					email: email,
					userID: userID,
					message: message
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			return response.data;
		} catch (e) {}
	};
	const deleteComment = async ({ id }) => {
		try {
			let args = {
				id: id
			};
			console.log("DEL: " + JSON.stringify(args));
			const response = await axios.post(
				server + "/delete_comment",
				args,
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			if (response.data.error != null) {
				console.log(response.data.error);
				return;
			}
			updateMainDataStore({
				consumptions: [
					{
						id: response.data.data[0].id,
						replies: response.data.data[0].replies,
						comment_count: response.data.data[0].comment_count
					}
				]
			});
		} catch (e) {}
	};
	const deleteConsumption = async ({ id, contentID, action }) => {
		try {
			let args = {};
			if (id != null) {
				args.id = id;
			} else {
				args.content = contentID;
				args.action = action;
			}
			const response = await axios.post(
				server + "/delete_consumption",
				args,
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			if (response.error != null) {
				console.log(response.error);
				return;
			}
			updateMainDataStore({
				consumptions: [response.data.consumption]
			});
		} catch (e) {
			console.log(e);
		}
		return;
	};
	const comment = async ({ consumptionID, comment }) => {
		try {
			let postData = {
				consumptionID: consumptionID,
				comment: comment
			};
			const response = await axios.post(
				server + "/comment",
				postData,
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			if (response.data.error != null) {
				console.log(response.data.error);
				return;
			}
			updateMainDataStore({
				consumptions: [
					{
						id: response.data.data[0].id,
						replies: response.data.data[0].replies,
						comment_count: response.data.data[0].comment_count
					}
				]
			});
		} catch (err) {
			console.log(err);
		}
		return;
	};
	const consume = async ({
		action,
		contentID,
		date,
		rating,
		comment,
		id,
		visibility
	}) => {
		try {
			if (date == null) {
				date = format(new Date(), "M/d/yyyy");
			}
			let postData = {
				content: contentID,
				timezone: new Date().getTimezoneOffset(),
				date: date,
				visibility: 0,
				action: action,
				visibility: visibility == null ? 0 : visibility
			};
			if (id !== null && typeof id !== "undefined") {
				postData.id = id;
			}
			if (rating !== null && typeof rating !== "undefined") {
				postData.rating = rating;
			} else if (action == 0) {
				postData.rating = null;
			}
			if (comment != null && typeof comment != "undefined") {
				postData.comment = comment;
			}
			const response = await axios.post(
				server + "/consume",
				postData,
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			if (response.data.error != null) {
				console.log(response.data.error);
				return;
			}
			updateMainDataStore({
				consumptions: [response.data.consumption]
			});
		} catch (err) {
			console.log(err);
		}
		return;
	};
	const getUser = async (username, forceClear) => {
		const match = Object.keys(mainDataStore.users).filter((u) => {
			return mainDataStore.users[u].name == username;
		});
		if (
			match.length == 0 ||
			forceClear === true ||
			match[0].stats == null
		) {
			try {
				const response = await axios.post(
					server + "/user",
					{
						name: username
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				updateMainDataStore({ users: [response.data] });
				return response.data;
			} catch (e) {
				console.log(e);
				return null;
			}
		} else {
			return mainDataStore.users[match[0]];
		}
	};
	const getLikers = async ({ id, type }) => {
		try {
			const response = await axios.post(
				server + "/likers",
				{
					id: id,
					type: type
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			return response.data;
		} catch (e) {
			return [];
		}
	};
	const getUserFollowers = async ({ username, force }) => {
		const u = await getUser(username);
		if (u.follow_info == null || force === true) {
			try {
				const response = await axios.post(
					server + "/user/followers",
					{
						id: u.id,
						includeFollowing: true
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				u.follow_info = response.data;
				u.stats.following = response.data.following.length;
				u.stats.follows = response.data.followers.length;
				updateUserCache(username, u);
				return u;
			} catch (e) {
				console.log(e);
				return null;
			}
		} else {
			return u;
		}
	};
	const getUserIntegration = async (type) => {
		const response = await axios.post(
			server + "/integrations",
			{
				type: type
			},
			{
				headers: {
					Authorization: user.token
				}
			}
		);
		return response.data;
	};
	const updateLikes = ({ consumptionID, commentID, liked, likes }) => {
		// THIS WILL NEED TO BE TWEAKED FOR LISTS, OBVS

		if (commentID != null) {
			if (
				mainDataStore.consumption[consumptionID] != null &&
				mainDataStore.consumption[consumptionID].replies != null
			) {
				updateMainDataStore({
					comments: [
						{
							id: commentID,
							consumptionID: consumptionID,
							liked: liked,
							likes: likes
						}
					]
				});
			}
		} else if (consumptionID != null) {
			if (mainDataStore.consumption[consumptionID] != null) {
				updateMainDataStore({
					consumptions: [
						{
							id: consumptionID,
							liked: liked,
							like_count: likes
						}
					]
				});
			}
		}
	};
	const like = async ({ consumptionID, commentID }) => {
		let response;
		if (commentID != null) {
			response = await axios.post(
				server + "/comment/like",
				{
					id: commentID
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
		} else if (consumptionID != null) {
			response = await axios.post(
				server + "/consumption/like",
				{
					id: consumptionID
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
		}
		updateLikes({
			consumptionID: consumptionID,
			commentID: commentID,
			liked: true,
			likes: response.data.likes
		});
	};
	const unlike = async ({ consumptionID, commentID }) => {
		let response;
		if (commentID != null) {
			response = await axios.post(
				server + "/comment/unlike",
				{
					id: commentID
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
		} else if (consumptionID != null) {
			response = await axios.post(
				server + "/consumption/unlike",
				{
					id: consumptionID
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
		}
		updateLikes({
			consumptionID: consumptionID,
			commentID: commentID,
			liked: false,
			likes: response.data.likes
		});
	};
	const integrationLogin = async (type, username, pass) => {
		const response = await axios.post(
			server + "/integrations/login",
			{
				type: type,
				username: username,
				pass: pass
			},
			{
				headers: {
					Authorization: user.token
				}
			}
		);
		return response.data;
	};
	const getRecommendations = async ({ requested_type }) => {
		if (recommendations == null) {
			let calls = [];
			const types = [
				"user",
				"movie",
				"book",
				"podcast",
				"album",
				"tv-show",
				"video-game"
			];
			types.forEach((type) => {
				if (
					requested_type == null ||
					type == requested_type.toLowerCase()
				) {
					axios.post(
						server + "/recommendations",
						{
							type: type
						},
						{
							headers: {
								Authorization: user.token
							}
						}
					).then((response) => {
						setRecommendations((oldRecs) => {
							let newRecs = { ...oldRecs };
							Object.keys(response.data).forEach((key) => {
								newRecs[key] = response.data[key];
							});
							return newRecs;
						});
					});
				}
			});
			return {};
		} else {
			return recommendations;
		}
	};
	const toggleFollow = async ({ userID: userID, mode: mode }) => {
		try {
			const response = await axios.post(
				server + "/user/" + mode,
				{
					id: userID
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			updateMainDataStore({
				users: [
					{
						id: response.data.id,
						followed: response.data.followed,
						following: response.data.following,
						stats: response.data.stats,
						clear: mode === "unfollow"
					}
				]
			});
			getUser(user.name, true);
			return response.data;
		} catch (e) {
			console.log(e);
			return null;
		}
	};
	const followUser = async (userID) => {
		return await toggleFollow({ userID: userID, mode: "follow" });
	};
	const unfollowUser = async (userID) => {
		return await toggleFollow({ userID: userID, mode: "unfollow" });
	};

	const getFollowers = async () => {
		try {
			const response = await axios.post(
				server + "/user/followers",
				{},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			return response.data;
		} catch (e) {}
	};
	const updateUserCache = (key, data) => {
		data.cachetime = new Date().getTime();
		setUserCache((oldCache) => {
			let updated_cache = {
				...oldCache
			};
			const oldest = getOldestInCache(updated_cache);
			if (oldest != null) {
				delete updated_cache[oldest];
			}
			updated_cache[key] = data;
			return updated_cache;
		});
	};

	const getOldestInCache = (cache) => {
		if (Object.keys(cache).length > 10) {
			let oldest = null;
			Object.keys(cache).forEach((key) => {
				if (oldest == null || cache[key].cachetime < oldest.age) {
					oldest = {
						id: key,
						age: cache[key].cachetime
					};
				}
			});
			return oldest.id;
		} else {
			return null;
		}
	};

	const updateContentCache = (key, data) => {
		data.cachetime = new Date().getTime();
		setContentCache((oldCache) => {
			let updated_cache = {
				...oldCache
			};
			const oldest = getOldestInCache(updated_cache);
			if (oldest != null) {
				delete updated_cache[oldest];
			}
			updated_cache[key] = data;
			return updated_cache;
		});
	};

	const search = async ({ type, string, parent, returnSeparates }) => {
		if (searchSource != null) {
			searchSource.cancel();
			setSearchSource(null);
		}
		const key = type + "-" + string;
		const source = axios.CancelToken.source();
		setSearchSource(source);
		try {
			const response = await axios({
				method: "post",
				url: server + "/search",
				data: {
					type: type,
					str: string,
					parent: parent,
					returnSeparates: returnSeparates
				},
				cancelToken: source.token,
				headers: {
					Authorization: user.token
				}
			});
			return response.data;
		} catch (e) {
			return no_connection_error;
		}
	};

	/** LISTS FUNCTIONS **/

	const getLists = async () => {
		try {
			const response = await axios.post(
				server + "/lists",
				{
					page: lists.page
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			setLists((old_data) => {
				let new_data = { ...old_data };
				new_data.lists = new_data.lists.concat(response.data);
				new_data.page++;
				return new_data;
			});
			return response.data.length == 10;
		} catch (e) {
			return false;
		}
	};

	const getList = async ({ id }) => {
		let matching = lists.cached[id];
		if (matching == null) {
			try {
				const response = await axios.post(
					server + "/list",
					{
						id: id
					},
					{
						headers: {
							Authorization: user.token
						}
					}
				);
				setLists((old_data) => {
					let new_data = { ...old_data };
					new_data.cached[id] = response.data;
					return new_data;
				});
				return response.data;
			} catch (e) {
				return {};
			}
		} else {
			return matching;
		}
	};

	const editList = async (list) => {
		try {
			const response = await axios.post(
				server + "/edit_list",
				{
					...list
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			setLists((old_data) => {
				let new_data = { ...old_data };
				let existing_key = Object.keys(new_data.cached).filter(
					(l) => {
						return new_data.cached[l].id == response.data.id;
					}
				);
				let base = {};

				if (existing_key.length > 0) {
					base = { ...new_data.cached[existing_key[0]] };
					delete new_data.cached[existing_key[0]];
				}
				Object.keys(list).forEach((key) => {
					base[key] = list[key];
				});
				base.link = response.data.link;
				base.id = response.data.id;
				new_data.cached[response.data.link] = base;
				new_data.lists.forEach((l) => {
					if (l.id == base.id) {
						l.name = base.name;
						l.description = base.description;
						l.link = base.link;
						l.items = base.content.length;
						l.images = base.content
							.filter((c) => c.image != null)
							.splice(0, 3)
							.map((c) => c.image);
						console.log(l);
					}
				});
				return new_data;
			});
			return response.data;
		} catch (e) {
			return { error: { message: "Could not contact the server" } };
		}
	};
	const deleteList = async ({ id }) => {
		try {
			const response = await axios.post(
				server + "/delete_list",
				{
					id: id
				},
				{
					headers: {
						Authorization: user.token
					}
				}
			);
			setLists((old_data) => {
				let new_data = { ...old_data };
				console.log({ d: new_data, i: id });
				new_data.lists = new_data.lists.filter((l) => {
					return l.id != id;
				});
				delete new_data.cached[id];
				return new_data;
			});
		} catch (e) {
			return { error: { message: "Could not contact the server" } };
		}
	};

	/** USER FUNCTIONS **/

	const forgotPassword = async ({ name }) => {
		try {
			const response = await axios.post(server + "/forgot_password", {
				name: name
			});
			return {};
		} catch (e) {
			return no_connection_error;
		}
	};
	const verifyToken = async (token) => {
		try {
			const response = await axios.post(
				server + "/verify_forgot_password",
				{
					token: token
				}
			);
			return response.data;
		} catch (e) {
			return no_connection_error;
		}
	};
	const editUser = async (u) => {
		try {
			let token = user.token;
			if (token == null) {
				token = u.token;
			}
			const response = await axios.post(server + "/edit_user", u, {
				headers: {
					Authorization: token
				}
			});
			if (response.data.error != null) {
				return response.data;
			} else {
				Cookies.set("u", response.data, { expires: 365 * 5 });
				setUser(response.data);
				updateMainDataStore({
					users: [
						{
							id: response.data.id,
							name: response.data.name
						}
					]
				});
				return {};
			}
		} catch (e) {
			return no_connection_error;
		}
	};
	const login = async ({ name, pass, new_user, email }) => {
		try {
			const response = await axios.post(server + "/login", {
				name: name,
				password: pass,
				new_user: new_user,
				email: email
			});
			if (response.data.error != null) {
				return response.data.error;
			} else {
				Cookies.set(
					"u",
					{
						id: response.data.id,
						name: response.data.name,
						token: response.data.token,
						email: response.data.email
					},
					{ expires: 365 * 5 }
				);
				setUser({
					id: response.data.id,
					name: response.data.name,
					token: response.data.token,
					email: response.data.email
				});
			}
		} catch (e) {
			return no_connection_error;
		}
	};
	const logout = () => {
		Cookies.remove("u");
		setFeed(defaultFeedState);
		setContentCache({});
		setNotifications([]);
		Object.keys(scrolls).forEach((key) => {
			delete scrolls[key];
		});
		setMainDataStore({
			feeds: {},
			consumption: {},
			content: {},
			users: {}
		});
		setCurrentFeed(defaultCurrentFeed);
		setUser({ id: null });
		setRecommendations();
	};
	const uploadImage = async (file) => {
		try {
			let formData = new FormData();
			formData.append("file", file);
			const response = await axios.post(
				server + "/user/image",
				formData,
				{
					headers: {
						Authorization: user.token,
						"Content-Type": "multipart/form-data"
					}
				}
			);
			updateMainDataStore({
				users: [
					{
						id: user.id,
						image: response.data.image
					}
				]
			});
			return true;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	/** UTILS **/

	const trimPath = (path) => {
		let bits = path.split("/");
		if (bits[0] == "") {
			bits.splice(0, 1);
		}
		let last_bit = bits[bits.length - 1];
		if (last_bit == "") {
			bits.pop();
		}
		let mode = "main";
		if (last_bit.indexOf("--") == 0) {
			if (last_bit == "--reviews") {
				mode = "reviews";
				bits.pop();
			} else if (last_bit == "--activity") {
				mode = "activity";
				bits.pop();
			} else if (last_bit.indexOf("-") > -1) {
				let sub_bits = last_bit.split("-");
				if (!isNaN(sub_bits[sub_bits.length - 1])) {
					mode = parseInt(sub_bits[sub_bits.length - 1]);
					bits.pop();
				}
			}
		}
		return {
			path: bits.join("/"),
			mode: mode
		};
	};

	const functions = {
		login: login,
		logout: logout,
		consume: consume,
		comment: comment,
		deleteComment: deleteComment,
		setScrolls: setScrolls,
		getContent: getContent,
		trimPath: trimPath,
		forgotPassword: forgotPassword,
		verifyToken: verifyToken,
		like: like,
		unlike: unlike,
		editUser: editUser,
		getUserIntegration: getUserIntegration,
		integrationLogin: integrationLogin,
		search: search,
		deleteConsumption: deleteConsumption,
		getNotifications: getNotifications,
		markNotificationsSeen: markNotificationsSeen,
		getUser: getUser,
		followUser: followUser,
		getRecommendations: getRecommendations,
		unfollowUser: unfollowUser,
		uploadImage: uploadImage,
		getFollowers: getFollowers,
		getUserFollowers: getUserFollowers,
		sendRecommendation: sendRecommendation,
		getLists: getLists,
		getList: getList,
		editList: editList,
		deleteList: deleteList,
		getLikers: getLikers,
		changeCurrentFeed: changeCurrentFeed,
		selectCurrentFeedContents: selectCurrentFeedContents,
		extendCurrentFeed: extendCurrentFeed,
		getScrollPosition: getScrollPosition,
		selectContentByPath: selectContentByPath,
		selectConsumptionDetails: selectConsumptionDetails,
		getConsumptionReplies: getConsumptionReplies,
		selectUserDetails: selectUserDetails
	};

	return (
		<div>
			<Helmet>
				<title>Consumie</title>
				<meta
					key="og-image"
					property="og:image"
					content="https://images.consumie.com/consumie-og.png"
				/>
				<meta
					key="og-image-width"
					property="og:image:width"
					content="630"
				/>
				<meta
					key="og-image-height"
					property="og:image:height"
					content="630"
				/>
				<meta
					key="twitter-image"
					name="twitter:image"
					content="https://images.consumie.com/consumie.png"
				/>
				<meta
					key="twitter-card"
					name="twitter:card"
					content="summary_large_image"
				/>
				<meta
					key="description"
					name="description"
					content="Consumie is a single location for tracking all of your media consumption - movies, music, books, podcasts, video games, and TV."
				/>
				<meta
					key="twitter-desc"
					name="twitter:description"
					content="Consumie is a single location for tracking all of your media consumption - movies, music, books, podcasts, video games, and TV."
				/>
				<meta
					key="twitter-title"
					name="twitter:title"
					content="Consumie"
				/>
				<meta key="og-title" name="og:title" content="Consumie" />
				<meta
					key="twitter-site"
					name="twitter:site"
					content="@consumie_"
				/>
			</Helmet>
			<BrowserRouter>
				<ContentRouter
					functions={functions}
					user={user}
					contentCache={contentCache}
					feed={feed}
					currentFeed={currentFeed}
					feeds={mainDataStore.feeds}
					notifications={notifications}
					userCache={userCache}
					recommendations={recommendations}
					display={display}
					lists={lists}
				/>
			</BrowserRouter>
		</div>
	);
}

export default App;
