import React, { Suspense, Component, lazy, useState, useEffect } from "react";
import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	Redirect,
	useLocation,
	useHistory
} from "react-router-dom";
import LeftNav from "./LeftNav";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const Content = lazy(() => import("./Content/Index"));
const Home = lazy(() => import("./Home/Feed"));
const Splash = lazy(() => import("./Home/Splash"));
const Login = lazy(() => import("./Login/Login"));
const Notifications = lazy(() => import("./Notifications/Notifications"));
const Add = lazy(() => import("./Consume/Add"));
const Recommend = lazy(() => import("./Recommend/Recommend"));
const ResetPassword = lazy(() => import("./Login/ResetPassword"));
const Delete = lazy(() => import("./Consume/Delete"));
const Settings = lazy(() => import("./User/Settings"));
const Filter = lazy(() => import("./Home/Filter"));
const Discover = lazy(() => import("./Discover/Discover"));
const Netflix = lazy(() => import("./User/Integrations/Netflix"));
const About = lazy(() => import("./About/About"));
const Lists = lazy(() => import("./Lists/Index"));
const List = lazy(() => import("./Lists/Detail"));
const EditList = lazy(() => import("./Lists/Edit"));
const Follows = lazy(() => import("./User/Follows"));
const Likes = lazy(() => import("./Components/Likes"));

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ContentRouter({
	functions,
	user,
	contentCache,
	userCache,
	feed,
	notifications,
	recommendations,
	display,
	lists,
	feeds,
	currentFeed
}) {
	// I know there is a Switch/Route system in ReactRouter, but it doesn't
	// do what I want for modals, so I am doing this manually

	let history = useHistory();
	let { pathname, search } = useLocation();
	const [locked, setLocked] = useState(false);
	const [snackbar, setSnackbar] = useState(null);
	const [lastpath, setLastpath] = useState();

	const content_types = {
		movie: 1,
		book: 1,
		album: 1,
		concert: 1,
		"video-game": 1,
		tv: 1,
		podcast: 1
	};
	const isModal = (url) => {
		const modals = {
			login: 1,
			add: 1,
			recommend: 1,
			register: 1,
			"forgot-password": 1,
			"reset-password": 1,
			delete: 1,
			edit: 1,
			filter: 1,
			"delete-comment": 1,
			netflix: 1,
			about: 1,
			likes: 1
		};
		if (modals[url[1]] != null) {
			return true;
		} else if (url[1] == "lists" && url[2] == "delete") {
			return true;
		} else if (
			url[1] == "user" &&
			url.length > 2 &&
			(url[3] == "followers" || url[3] == "following")
		) {
			return true;
		} else {
			return false;
		}
	};
	useEffect(() => {
		let url = pathname.split("/");
		if (!isModal(url)) {
			setLastpath(url.join("/"));
		}
	}, [pathname]);

	const clearSnackbar = () => {
		setSnackbar(null);
	};

	const loading = <div />;

	const closeModal = (e, forceHome, successMessage, destination) => {
		if (e != null && e.target != e.currentTarget) {
			return;
		}
		if (
			e != null &&
			e.target.getAttribute("data-id") === "bg" &&
			locked
		) {
			return;
		}
		if (destination != null) {
			history.push(destination);
		} else if (lastpath == null || forceHome === true) {
			history.push("/");
		} else {
			history.goBack();
		}
		if (successMessage != null) {
			setSnackbar(successMessage);
		}
	};

	const lockModal = (locked) => {
		setLocked(locked);
	};

	functions.closeModal = closeModal;
	functions.lockModal = lockModal;

	let url = pathname.split("/");

	let content = null;
	let left_nav = (
		<LeftNav
			key="left"
			notifications={notifications}
			functions={functions}
			user={user}
		/>
	);

	if (url[url.length - 1] == "") {
		url.pop();
	}
	let modal = null;
	let fixed_url = null;
	if (isModal(url)) {
		if (url[1] == "add") {
			let objectID = url[url.length - 1];
			fixed_url = [""].concat(url.slice(2));

			modal = (
				<Suspense fallback="">
					<Add
						functions={functions}
						content={
							url.length > 2
								? functions.selectContentByPath({
										path: url.slice(2).join("/")
								  })
								: null
						}
					/>
				</Suspense>
			);
		} else if (url[1] == "edit") {
			let objectID = url[url.length - 1];
			if (objectID.indexOf("--") == 0) {
				objectID = objectID.substr(objectID.lastIndexOf("-") + 1);
				fixed_url = [""].concat(url.slice(2));
				modal = (
					<Suspense fallback="">
						<Add
							functions={functions}
							edit={true}
							consumption={functions.selectConsumptionDetails(
								{
									id: objectID
								}
							)}
						/>
					</Suspense>
				);
			}
		} else if (url[1] == "likes") {
			let objectID = url[url.length - 1];
			let objectType = null;
			if (objectID.indexOf("--") == 0) {
				objectID = objectID.substr(objectID.lastIndexOf("-") + 1);
				fixed_url = [""].concat(url.slice(2));
				objectType = "consumption";
			} else {
				if (url[2] == "lists") {
					fixed_url = [""].concat(url.slice(2));
					objectType = "list";
				} else {
					fixed_url = [""].concat(url.slice(2, url.length - 1));
					objectType = "comment";
				}
			}
			modal = (
				<Suspense fallback="">
					<Likes
						id={objectID}
						functions={functions}
						viewer={user}
						type={objectType}
					/>
				</Suspense>
			);
		} else if (url[1] == "recommend") {
			modal = (
				<Suspense fallback="">
					<Recommend
						functions={functions}
						pathname={url.slice(2).join("/")}
						contentCache={contentCache}
						user={user}
					/>
				</Suspense>
			);
		} else if (url[1] == "filter") {
			modal = (
				<Suspense fallback="">
					<Filter
						functions={functions}
						currentFeed={
							currentFeed == null
								? null
								: currentFeed[currentFeed.state]
						}
					/>
				</Suspense>
			);
		} else if (url[1] == "about") {
			modal = (
				<Suspense fallback="">
					<About functions={functions} />
				</Suspense>
			);
		} else if (url[1] == "netflix") {
			modal = (
				<Suspense fallback="">
					<Netflix functions={functions} user={user} />
				</Suspense>
			);
		} else if (url[1] == "reset-password") {
			modal = (
				<Suspense fallback="">
					<ResetPassword user={user} functions={functions} />
				</Suspense>
			);
		} else if (
			url[1] == "login" ||
			url[1] == "register" ||
			url[1] == "forgot-password"
		) {
			modal = (
				<Suspense fallback="">
					<Login user={user} functions={functions} />
				</Suspense>
			);
		} else if (url[1] == "user") {
			modal = (
				<Suspense fallback="">
					<Follows
						user={url[2]}
						mode={url[3]}
						viewer={user}
						functions={functions}
					/>
				</Suspense>
			);
			fixed_url = [url[0], url[1], url[2]];
		} else if (
			url[1] == "delete" ||
			url[1] == "delete-comment" ||
			url[1] == "lists"
		) {
			const last_path = lastpath;
			let destination = null;
			if (
				last_path != null &&
				last_path.substr(last_path.lastIndexOf("-") + 1) == url[2]
			) {
				destination = "/";
			}
			let id = url[2];
			let type = "consumption";
			if (url[1] == "delete-comment") {
				type = "comment";
			} else if (url[1] == "lists") {
				type = "list";
				id = url[4];
				fixed_url = [url[0], url[1], "edit", url[3], url[4]];
				destination = "/lists/";
			}
			modal = (
				<Suspense fallback="">
					<Delete
						id={id}
						type={type}
						functions={functions}
						destination={destination}
					/>
				</Suspense>
			);
		}
		if (lastpath == null) {
			if (fixed_url == null) {
				url = [""];
			} else {
				url = fixed_url;
				setLastpath(fixed_url.join("/"));
			}
		} else {
			url = lastpath.split("/");
		}
	}
	let hide_left_nav = false;
	if (content_types[url[1]] != null && url.length > 2) {
		const mode = functions.trimPath(url.join("/")).mode;
		content = (
			<Content
				key="content"
				user={user}
				notifications={notifications}
				functions={functions}
				content={functions.selectContentByPath({
					path: url.join("/")
				})}
				consumption={
					isNaN(mode)
						? null
						: functions.selectConsumptionDetails({ id: mode })
				}
				pathname={url.join("/")}
			/>
		);
	} else if (url[1] == "discover") {
		content = (
			<Discover
				key="discover"
				user={user}
				functions={functions}
				recommendations={recommendations}
			/>
		);
	} else if (url[1] == "lists") {
		if (url.length > 2) {
			if (url[2] == "edit" || url[2] == "new") {
				content = (
					<EditList
						key="list"
						lists={lists}
						user={user}
						functions={functions}
						name={url[3]}
						id={url[4]}
					/>
				);
			} else {
				content = (
					<List
						key="list"
						lists={lists}
						user={user}
						functions={functions}
						id={url[3]}
					/>
				);
			}
		} else {
			content = (
				<Lists
					key="lists"
					lists={lists}
					user={user}
					functions={functions}
				/>
			);
		}
	} else if (url[1] == "notifications") {
		content = (
			<Notifications
				key="notifications"
				functions={functions}
				notifications={notifications}
				user={user}
			/>
		);
	} else if (url[1] == "settings") {
		content = (
			<Settings
				key="settings"
				functions={functions}
				viewer={user}
				user={functions.selectUserDetails({ id: user.id })}
			/>
		);
	} else {
		if (user.id == null) {
			left_nav = null;
			content = <Splash key="splash" functions={functions} />;
		} else {
			const userName = url[1] == "user" ? url[2] : null;
			const bookmarks = url[1] == "bookmarks";
			const key = bookmarks
				? currentFeed.bookmarks
				: currentFeed.default;

			content = (
				<Home
					key="home"
					user={user}
					beta={search == "?beta"}
					functions={functions}
					mode={bookmarks ? "bookmarks" : "default"}
					currentFeed={key}
					userName={userName}
					feed={functions.selectCurrentFeedContents({
						group: true
					})}
				/>
			);
			if (!bookmarks && userName == null) {
				left_nav = (
					<LeftNav
						key="left"
						notifications={notifications}
						functions={functions}
						user={user}
						homeAction="scroll"
					/>
				);
			}
		}
	}
	const main = document.getElementsByClassName("main")[0];
	if (modal != null) {
		try {
			main.style.overflow = "hidden";
		} catch (e) {}
		/**
          modal = (
               <div role="dialog" key="dialog">
                    <div key="background" className="fade modal-backdrop in" />
                    <div
                         key="modal"
                         role="dialog"
                         tabIndex="-1"
                         onClick={closeModal}
                         data-id="bg"
                         className="fade org-modal in modal">
                         <div className="modal-dialog">
                              <div className="modal-content" role="document">
                                   <div className="modal-body">{modal}</div>
                              </div>
                         </div>
                    </div>
               </div>
          );
          **/
		/**
		modal = (
			<Modal
				open={true}
				onClose={closeModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box className="modal-box">
					<div className="modal-body">{modal}</div>
				</Box>
			</Modal>
		);
		**/
	} else {
		try {
			main.style.overflow = "auto";
		} catch (e) {}
	}
	return [
		left_nav,
		<Suspense fallback={loading} key="loading">
			{content}
			{modal}
			<Snackbar
				open={snackbar != null}
				onClose={clearSnackbar}
				autoHideDuration={4000}>
				<Alert
					onClose={clearSnackbar}
					severity="success"
					sx={{ width: "100%" }}>
					{snackbar}
				</Alert>
			</Snackbar>
		</Suspense>
	];
}

export default ContentRouter;
